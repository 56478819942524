.c-table {
    width: 100%;
    border-collapse: collapse;

    &__header-cell {
        text-align: left;
    }

    &__data-cell {
        border-top: dashed 1px lightgrey;
    }

    &__data-cell,
    &__header-cell {
        padding: 1.25rem 0.75rem;
        width: 1%;
        white-space: nowrap;

        &--shrink {
            width: 0.000001%;
        }

        &--align-left {
            text-align: left;
        }

        &--align-right {
            text-align: right;
        }

        &--align-center {
            text-align: center;
        }
    }
}