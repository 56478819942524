.c-grouped-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__detail {
        background-color: white;
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &__detail-content {

    }

    &__detail-nav {
        display: flex;

    }

    &__detail-nav-item {
        padding: 0.5rem 1rem;
        cursor: default;
        //border-bottom: solid 2px white;
        transition: background-color 100ms;
        font-weight: 600;
        color: grey;
        user-select: none;

        &:hover {
            background-color: #f6f6f6;
        }

        &--active {
            color: #009ef7;
            border-bottom: solid 2px #009ef7;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &--hidden {
            display: none;
        }
    }
}
