@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-breadcrumb {
    display: inline-flex;

    &__connector {
        color: map.get(colors.$general, "darkgrey");
        display: inline-block;
        padding: 0 map.get(spaces.$general, "xs");
        user-select: none;
    }

    &__item {
        color: map.get(colors.$general, "darkgrey");
        display: inline-block;


        &--current {
            color: map.get(colors.$general, "black");
            font-weight: 600;
        }
    }
}
