@use "sass:map";
@use "../settings/_border-radiuses" as br;
@use "../settings/_spaces";
@use "../settings/colors";

.c-card {
    background-color: #fff;
    //border-radius: map.get(br.$border-radiuses, $key: "md");
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    display: flex;
    flex-direction: column;

    &--login {
        display: flex;
        flex-direction: column;
        gap: map.get(spaces.$general, "xl");
        max-width: 17.5rem;
        padding: map.get(spaces.$general, "2xl");
        width: 100%;
    }

    &__header,
    &__content,
    &__footer {
        padding: map.get(spaces.$general, "md") map.get(spaces.$general, "lg");
    }

    &__header {
        font-weight: 600;
        font-size: 1.25rem;
        display: flex;
        justify-content: space-between;
        border: 1px solid #f4f4f4;
    }

    &__content {
        flex-grow: 1;
    }

    &__footer {
        border-top: 1px solid map.get(colors.$theme, "background-light-1");
        display: flex;
        justify-content: flex-end;
    }
}
