$bullet-size: 1rem;
$padding: 6px;

.c-switch {
    width: 2rem;
    border-radius: calc(($bullet-size + ($padding * 2)) / 2);
    background-color: #c2c2c2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    height: $bullet-size;
    padding: $padding;
    box-sizing: content-box;
    transition: all 200ms;

    &::after {
        background-color: white;
        border-radius: 50%;
        content: "";
        display: block;
        height: $bullet-size;
        position: absolute;
        width: $bullet-size;
        transition: all 200ms;
    }

    &:checked {
        background-color: #009ef7;

        &::after {
            right: $padding;
        }
    }

    &:not(&:checked) {
        &::after {
            right: calc(100% - $padding - $bullet-size);
        }
    }
}
