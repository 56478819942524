@use "sass:map";
@use "../mixins/form";
@use "../settings/colors";
@use "../settings/spaces";

.c-radio-button {
    display: flex;
    align-items: center;
    gap: map.get(spaces.$general, "sm");

    &__description {
        @include form.description;
    }

    &__input {
        appearance: none;
        background-color: map.get(colors.$general, "grey");
        border-radius: 50%;
        border: 0.4rem solid map.get(colors.$general, "grey");
        height: 1.5rem;
        position: relative;
        width: 1.5rem;
    }

    &__label {
        @include form.label;
    }

    &__label-container {
        display: flex;
        flex-direction: column;
    }

    &:checked {
        background-color: white;
        border-color: map.get(colors.$theme, "primary");
    }
}
