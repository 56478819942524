@use "sass:map";
@use "../settings/colors";
@use "../tools/webfont" as wf;

body {
    color: map.get(colors.$general, "black");
    font-size: 1rem;
    line-height: 1.5;
    @include wf.load(special);
}
