@use "sass:map";
@use "../mixins/form";
@use "../settings/_border-radiuses" as br;
@use "../settings/_spaces";
@use "../settings/colors";

.c-text-field {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &__description {
        @include form.description;
        margin-top: map.get(spaces.$general, "xs");
    }

    &__input-container {
        position: relative;
        flex-grow: 1;
        width: 100%;
    }

    &__input {
        border-radius: map.get(br.$border-radiuses, "sm");
        border: solid 1px #e1e3ea;
        box-shadow: none;
        box-sizing: border-box;
        cursor: default;
        padding: 0.625rem map.get(spaces.$general, "sm");
        outline: none;
        transition: 125ms background-color ease-in, 125ms border-color ease-in;
        width: 100%;

        &:focus {
            box-shadow: 0 0 0 2px #cbd6ee;
            outline: none;
        }

        &:hover {
            background-color: #f6f6f6;
        }

        &--type-number {
            text-align: center;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &--type-password {
            padding-right: 2.75rem;
        }

        &--textarea {
            resize: vertical;
        }
    }

    &__label {
        @include form.label;
        margin: 0 0 0.375rem 0;
    }

    &__required-sign {
        color: map.get(colors.$theme, "danger");
    }

    &__show-password-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: map.get(spaces.$general, "sm");
        transition: all 200ms;

        &:hover {
            color: #999;
        }
    }
}
