.c-modal {
    position: relative;
    z-index: 50;
    transition: opacity 200ms;

    &--hidden {
        opacity: 0;
    }

    &--visible {
        opacity: 1;
    }

    &__background {
        position: fixed;
        inset: 0;
        background-color: rgba(100, 100, 100, 0.4);
    }

    &__windows-container {
        position: fixed;
        inset: 0;
        background-color: rgba(100, 100, 100, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__window {
        flex-grow: 0;
        background-color: white;
        padding: 1rem;
        border-radius: 5px;
    }
}
