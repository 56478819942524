.c-tag {
    background-color: #c8c8c833;
    border-radius: .25rem;
    font-size: 85%;
    font-weight: 600;
    margin: 0;
    padding: .2em .4em;

    &--success {
        color: #50cd89;
        background-color: #e8fff3;
    }

    &--warning {
        color: #ffc700;
        background-color: #fff8dd;
    }
}
