@use "sass:map";
@use "../settings/colors";
@use "../settings/_spaces";

.c-admin-menu {
    text-decoration: none;
    padding: map.get(spaces.$general, "md") 0;

    &__item-list {
        padding: 0;
    }

    &__item {
        color: map.get(colors.$admin-menu, "font");
        cursor: pointer;
        display: flex;
        align-items: center;
        list-style: none;
        padding: map.get(spaces.$general, "sm");
        transition: color 100ms;
        position: relative;

        &--is-active {
            background-color: map.get(colors.$admin-menu, "active-link-bg");
            color: map.get(colors.$admin-menu, "active-link-font");

            &:hover {
                color: map.get(colors.$admin-menu, "active-link-font");
            }

            &::after {
                border-bottom-width: 22px;
                border-color: transparent;
                border-right-color: map.get(colors.$layout, "content-bg");
                border-style: solid;
                border-top-width: 22px;
                border-left-width: 10px;
                border-right-width: 10px;
                bottom: 0;
                content: " ";
                height: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
            }
        }

        &:hover:not(&--is-active) {
            background-color: #1b1b28;
            color: map.get(colors.$admin-menu, "active-link-font");
        }
    }

    &__link-icon {
        width: 2rem;
        height: 1em;
        display: inline-block;
    }
}
