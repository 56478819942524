@use "sass:map";
@use "../settings/colors";

@mixin label {
    font-weight: 600;
    font-size: 0.9375rem;
}

@mixin description {
    color: map.get(colors.$general, "darkgrey");
    font-size: 0.8rem;
}
