@use "sass:map";
@use "../settings/colors";

a {
    text-decoration: none;
    color: #009ef7;

    &:hover {
        color: map.get(colors.$theme, "primary")
    }
}