@use "sass:map";
@use "../mixins/form";
@use "../settings/colors";
@use "../settings/spaces";

.c-list {
    display: flex;
    flex-direction: column;
    gap: map.get(spaces.$general, "md");

    &__body {
        display: flex;
        flex-direction: column;
        gap: map.get(spaces.$general, "md");
        margin: 0;
        padding: 0;

        & > &__item:not(:last-child) {
            border-bottom: dashed 1px map.get(colors.$general, "grey");
        }
    }

    &__item {
        display: block;
        //padding: map.get(spaces.$general, "xs");
    }

    &__item-label {

    }

    &__title {
        @include form.label;
    }
}
