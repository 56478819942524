@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-admin-layout {
    display: grid;
    grid-template-columns: 16rem auto;
    grid-template-rows: min-content auto;
    grid-template-areas:
    "logo topbar"
    "menu main-content";
    height: 100vh;

    &__logo {
        background-color: map.get(colors.$layout, "logo-bg");
        display: flex;
        flex-direction: column;
        grid-area: logo;
        justify-content: center;
        padding: 0 map.get(spaces.$general, "md");
        position: relative;
    }

    &__menu {
        background-color: map.get(colors.$layout, "sidebar-bg");
        grid-area: menu;
    }

    &__main-content {
        background-color: map.get(colors.$layout, "content-bg");
        grid-area: main-content;
        padding: map.get(spaces.$general, "md");
        position: relative;
    }
    
    &__topbar {
        background-color: #ffffff;
        grid-area: topbar;
    }
}