@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-topbar {
    &__toolbar {
        background-color: map.get(colors.$layout, "sidebar-bg");;
        padding: map.get(spaces.$general, "sm") map.get(spaces.$general, "lg");
        display: flex;
        justify-content: flex-end;
    }

    &__navbar {
        align-items: center;
        display: flex;
        gap: map.get(spaces.$general, "md");
        padding: map.get(spaces.$general, "2xs") map.get(spaces.$general, "md");
    }

    &__page-name {
        border-right: solid 1px #ccc;
        display: inline-block;
        font-size: 1.25rem;
        padding-right: map.get(spaces.$general, "lg");
        min-width: 10rem;
        text-align: center;
    }
}