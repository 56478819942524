.c-repair-items {
    width: 100%;

    &__item {

    }

    &__name-field {
    }

    &__price-field,
    &__quantity-field {
        width: 8rem;
    }

    &__controls-field {
        width: 3rem;
    }
}
